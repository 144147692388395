import {
  ButtonClickTrackingPlugin,
  enableButtonClickTracking
} from '@montugroup/montu-browser-plugin-button-click-tracking';
import { LinkClickTrackingPlugin, enableLinkClickTracking } from '@montugroup/montu-browser-plugin-link-click-tracking';
import { enableActivityTracking, newTracker, trackPageView } from '@snowplow/browser-tracker';
import type { FC, ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import { SNOWPLOW_COLLECTION_ENDPOINTS } from './snowplow.constants';
import type { Props } from './snowplow.types';
import { getEnvironment } from './utils';

type BrowserTracker = ReturnType<typeof newTracker>;
export const Snowplow: FC<Props> = ({ userId, appId, trackerName, pageViewContext, children }) => {
  const tracker = useRef<BrowserTracker | null>(null);
  useEffect(() => {
    try {
      const snowplowEndpoint = SNOWPLOW_COLLECTION_ENDPOINTS[getEnvironment()];
      tracker.current = newTracker(trackerName, snowplowEndpoint, {
        appId,
        plugins: [ButtonClickTrackingPlugin(), LinkClickTrackingPlugin()]
      });
      enableButtonClickTracking();
      enableLinkClickTracking();
      enableActivityTracking({
        minimumVisitLength: 10,
        heartbeatDelay: 10
      });

      if (pageViewContext) {
        trackPageView({
          context: pageViewContext
        });
      } else {
        trackPageView();
      }
    } catch (error) {
      console.error('Failed to initialise Snowplow', { error });
    }
  }, [appId, trackerName, pageViewContext]);

  useEffect(() => {
    if (tracker.current && userId) {
      tracker.current?.setUserId(userId);
    }
  }, [tracker, userId]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

// Helper for conditional loading from FF etc
export const WithSnowplow = (component: ReactElement, snowPlowProps: Omit<Props, 'children'>, enabled: boolean) => {
  if (enabled) {
    return <Snowplow {...snowPlowProps}>{component}</Snowplow>;
  }

  return component;
};

export default Snowplow;
